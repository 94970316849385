import './App.css';
import Home from './pages/Home';

function App() {
  return (
    <div className="App"
    style={{
      backgroundColor: "#1E1E1E"
  }}
  >
        <Home/>
    </div>
  );
}

export default App;
